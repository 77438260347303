import { Injectable, NgModule } from '@angular/core';
import {Observable} from 'rxjs';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoragesService } from 'src/app/services/storage/storages.service';


@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {

    /**
     *
     */
    private except = [
        'login',
        'esqueci-senha'
    ];

    private api_keys = {
        admin : 'admin_api_token', 
        user : 'api_token'
    }
    constructor(
        private storageService: StoragesService
    ) {}

    /**
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.inExceptList(req.url)) {
            const api_token = this.isAdmin(req.url)? this.storageService.getData(this.api_keys.admin) : this.storageService.getData(this.api_keys.user)
            const dupReq = req.clone({
                setParams: {
                   api_token
                },
                headers: req.headers.set('Accept', 'application/json'),
            });

            return next.handle(dupReq);
        }

        else {
            return next.handle(req);
        }
    }

    isAdmin(url: string): boolean{
        if(url.includes('admin')) return true;
        return false;
    }

    /**
     *
     * @param url
     */
    inExceptList(url: string): boolean {
        let ret = false;
        this.except.forEach(end_point => {
            if (url.endsWith(end_point)) ret = true;
        });
        return ret;
    }
};

@NgModule({
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpsRequestInterceptor, multi: true }
    ]
})
export class InterceptorModule {}
