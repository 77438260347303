import { NgModule } from '@angular/core';
import { AdminLinklistComponent } from './admin-linklist.component';


@NgModule({
  declarations: [
    
    
    AdminLinklistComponent
  ],
  imports: [
  ],
  exports: [
    AdminLinklistComponent
  ]
})
export class AdminLinklistComponentModule { }
