import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-linklist',
  templateUrl: './linklist.component.html'
})
export class LinklistComponent implements OnInit {

  
  constructor() { }

  ngOnInit(): void {
  }

}
