<nav class="navbar sticky-top navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="#">
      <img [src]="logo" />
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ng-template [ngIf]='isAdm'>
            <app-admin-linklist class="navbar-nav mr-auto"></app-admin-linklist>
        </ng-template>
        <ng-template [ngIf]='!isAdm'>
            <app-linklist class="navbar-nav mr-auto" ></app-linklist>
        </ng-template>
        <ul class="navbar-nav">
            <li class="nav-item active">
                <button (click)='logoutClickHandler()' class='btn btn-outline-dark '> Sair </button>
            </li>
        </ul>
    </div>
</nav>
