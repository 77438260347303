import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-linklist',
  templateUrl: './admin-linklist.component.html',
  styleUrls: ['./admin-linklist.component.scss']
})
export class AdminLinklistComponent implements OnInit {

  
  constructor() { }

  ngOnInit(): void {
  }

}
