import { NgModule } from '@angular/core';
import { LinklistComponent } from './linklist.component';


@NgModule({
  declarations: [
    
    
    LinklistComponent
  ],
  imports: [
    
  ],
  exports: [
    LinklistComponent
  ]
})
export class LinklistComponentModule { }
