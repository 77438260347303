import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment'
import { KeyToStorage, LoginGetResponse } from 'src/app/interfaces/interfaces';
import { StoragesService } from '../storage/storages.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private API_AUTH_URL : string = environment.authUrl;
  private API_ADM_LOGIN : string = '/admin/auth/login'

  private api_keys = {
    admin : 'admin_api_token', 
    user : 'api_token'
}
  constructor(
    private http: HttpClient,
    private storageService : StoragesService
  ) { }

  attemptAdminLogin(email: string, password: string): Observable<LoginGetResponse>{
    return this.http.post<LoginGetResponse>(`${this.API_AUTH_URL}${this.API_ADM_LOGIN}`,{email, password})
  }

  async loginAdminHandler(params: {email: string, password: string}): Promise<boolean>{
    return new Promise((resolve, reject) => {
      let {email, password} = params;
      this.attemptAdminLogin(email, password).subscribe(
        (user) => {
          this.storageKeys([{name:this.api_keys.admin, value: user.data.api_token}],this.storageService.storages.local)
          resolve(true);
        },
        (err) => {
          console.log(err)
          reject(err.error);
        }
      )

    })
  }

  logout(isAdmin: boolean = false){
    return new Promise((resolve, reject) => {

        if(isAdmin){
          this.storageService.clearData(this.api_keys.admin)
          resolve(true);
      }else if(!isAdmin){
          this.storageService.clearData(this.api_keys.user)
        resolve(true);
      }
      reject(false)
    })
  }

  storageKeys(keys: KeyToStorage[], local:string){
    keys.forEach(el =>{
      this.storageService.setData(el.name, el.value, local);
    })
  }

  
  
}
