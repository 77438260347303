import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appDragAndDrop]'
})
export class DragAndDropDirective {

  constructor() { }

  @HostListener('dragover', ['$event']) public onDragOver(event: Event){
    event.preventDefault();
    event.stopPropagation();

    console.log('Drag Over');
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(event: Event){
    event.preventDefault();
    event.stopPropagation();

    console.log('Drag Leave');

  }

  @HostListener('drop', ['$event']) public onDrop(event: any){
    event.preventDefault();
    event.stopPropagation();

    console.log('Drop');
    const files = event.dataTransfer.files
  }

}
