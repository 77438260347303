import { Injectable } from '@angular/core';
import Swal, { SweetAlertResult } from 'sweetalert2'
import { DateTime } from "luxon";
import { renderFlagCheckIfStmt } from '@angular/compiler/src/render3/view/template';

@Injectable({
  providedIn: 'root'
})
export class UltilitiesService {


  private params: any = {address: {}, protocol: {}};
  private Loading = Swal.mixin({
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    width: '8em',
    heightAuto: false,
    padding: '1rem 0 2rem 0'
  })

  private imageMime = ['89504e47','47494638','ffd8ffe0','ffd8ffe1','ffd8ffe2','ffd8ffe3','ffd8ffe8','ffd8ffee'];

  private certifiedMime = ['3082f33'];

  private zipAndRarMime = ['504b34', '504b56', '504b78'/*, '52617221' rar*/]

  private fdbMime = ['103930']

  constructor(

    ) { }

  formatDate(date: string, from :string = 'yyyy-MM-dd', to:string = 'dd/MM/yyyy'){
    let ret = date;
    if(date.length > from.length) ret = date.substring(0, from.length);
    return DateTime.fromFormat(ret, from).toFormat(to);
  }

  getDate(withTime: boolean = false){
    return withTime? DateTime.local().setZone('UTC-3').toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS) : DateTime.local().setZone('UTC-3').toLocaleString(DateTime.DATE_SHORT);
  }

  setData(index: string, data: any): void{
    this.params[index] = data;
  }

  getData(index: string): any{
    return this.params[index]
  }

  onlyNumbers(value: string) : string {
    const reg = /[0123456789]/g
    let code = value.match(reg);
    let ret = '';
    code?.map(el => ret = ret + el)
    return ret;
  }


  checkFdbExtension(fileName: string){
    let ret = false;
    if(fileName.split('.').slice(-1)[0].toLowerCase() === 'fdb') ret = true;
    return ret;
  }


  toBase64(file : File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let temp = reader.result?.toString() as string;
        let ret = temp.slice(temp.indexOf('64,')+3)
        resolve(ret)
      }
      reader.onerror = error => reject(error);
    });
  }

  checkIfIsImage(file: File): Promise<{image:boolean, mime:string}>{
    return new Promise((resolve, reject) => {
      this.getFileMime(file).then(
        mime => {
          if(this.imageMime.includes(mime)) resolve({image:true, mime});
          resolve({image:false, mime})
        }
      ).catch(err => reject(err))
    });
  }

  checkIfIsPFX(file: File): Promise<{pfx:boolean, mime:string}> {
    return new Promise((resolve, reject) => {
      this.getFileMime(file).then(
        mime => {
          if(this.certifiedMime.includes(mime)) resolve({pfx:true, mime});
          resolve({pfx:false, mime})
        }
      ).catch(err => reject(err))
    });
  }

  checkIfIsCompactFile(file: File): Promise<{compacted:boolean, mime:string}>{
    return new Promise((resolve, reject) => {
      this.getFileMime(file).then(
        mime => {
          if(this.zipAndRarMime.includes(mime)) resolve({compacted:true, mime});
          resolve({compacted:false, mime})
        }
      ).catch(err => reject(err))
    });
  }

  uploadCampactFile(file: File): Promise<string>{
    return new Promise( async (resolve, reject)=>{
      let {compacted, mime} = await this.checkIfIsCompactFile(file);
      if(compacted) resolve(this.toBase64(file))
      else reject({mime, error:'Documento não identificado como Arquivo compactado (zip)'});
    })
  }


  checkIfIsFdb(file: File): Promise<{compacted:boolean, mime:string}>{
    return new Promise((resolve, reject) => {
      this.getFileMime(file).then(
        mime => {
          if(this.fdbMime.includes(mime) && this.checkFdbExtension(file.name)) resolve({compacted:true, mime});
          resolve({compacted:false, mime})
        }
      ).catch(err => reject(err))
    });
  }

  uploadFdbFile(file: File): Promise<string>{
    return new Promise( async (resolve, reject)=>{
      let {compacted, mime} = await this.checkIfIsFdb(file);
      if(compacted) resolve(this.toBase64(file))
      else reject({mime, error:'Documento não identificado como Banco de dados Firebird (.fdb)'});
    })
  }

  getFileMime(file: File): Promise<string>{
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file.slice(0,4));
      reader.onloadend = (e) => {
        let arr = (new Uint8Array(e.target?.result as ArrayBuffer)).subarray(0, 4)
        let mime = "";
        for(var i = 0; i < arr.length; i++) {
          mime += arr[i].toString(16);
       }
        resolve(mime)
      }
      reader.onerror = error => reject(error);
    })
  }

  strCapitalize(text: string){
    let ret = text.toLowerCase();
    return ret.charAt(0).toUpperCase() + ret.slice(1);
  }

  uploadCertifiedFile(file: File): Promise<string>{
    return new Promise( async (resolve, reject)=>{
      let {pfx, mime} = await this.checkIfIsPFX(file);
      if(pfx) resolve(this.toBase64(file))
      else reject({mime, error:'Documento não identificado como certificado'});
    })
  }

  uploadImageFile(file: File): Promise<string>{
    return new Promise( async (resolve, reject)=>{
      let {image, mime} = await this.checkIfIsImage(file);
      if(image) resolve(this.toBase64(file))

      else reject({mime, error:'Documento não identificado como Imagem'});
    })
  }

  swal( title:string = '', text:string = '', option :string = '') : Promise<SweetAlertResult>{
    switch(option){
      case 'question':
        return new Promise((resolve, reject) => {
          Swal.fire({
          title,
          text,
          icon:option,
          showDenyButton: true,
          confirmButtonText: 'Sim',
          denyButtonText: 'Não',
        }).then(
          value => {
            value.isConfirmed  && resolve(value);
            value.isDenied && reject(value)
          }
        ).catch(
          err => {
            console.log(err);
            reject(err)
          }
        )
      })
      case 'warning':
      case 'error':
      case 'success':
      case 'info':
        return Swal.fire(title, text, option);
      default:
        return Swal.fire(title, text);
    }
  }

  toast(text: string, option:string): Promise<SweetAlertResult>{

    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    switch(option){
      case 'warning':
      case 'error':
      case 'success':
      case 'info':
        return Toast.fire({ icon: option, title: text });
      default:
        return Toast.fire({ title: text });;
    }
  }

  showLoading(){
    this.Loading.fire();
    this.Loading.showLoading();
  }

  hideLoading(){
    this.Loading.close();
  }

  validateEmail(email:string) {
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
}
