import { Injectable, Inject } from '@angular/core';
import { SESSION_STORAGE, LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class StoragesService {

  storages:{local:string, session: string} = {local:'LOCAL_STORAGE', session: 'SESSION_STORAGE'};

  constructor(
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    @Inject(LOCAL_STORAGE) private localStorage: StorageService
  ) { }

  clearStorage(storage: string = 'both'){
    if(storage != 'LOCAL_STORAGE')  this.sessionStorage.clear();
    if(storage != 'SESSION_STORAGE')  this.localStorage.clear();

  }

  clearData(key: string){
    if(this.localStorage.has(key))  this.localStorage.remove(key);
    if( this.sessionStorage.has(key))  this.sessionStorage.remove(key);
  }

  setData(key: string, data: any, storage: string){
    this.clearData(key)
    if(storage == 'SESSION_STORAGE')  this.sessionStorage.set(key, data);
    else if(storage == 'LOCAL_STORAGE') this.localStorage.set(key, data);
  }

  getData(key: string): any{
    if(this.localStorage.has(key)) return this.localStorage.get(key);
    else if( this.sessionStorage.has(key)) return this.sessionStorage.get(key);
    return null;
  }

  checkData(key: string): boolean{
    if(this.localStorage.has(key)) return true;
    else if( this.sessionStorage.has(key)) return true;
    return false;

  }

}
