import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UltilitiesService } from 'src/app/services/ultilities/ultilities.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Input() isAdm:boolean = false;

  logo:string = '/assets/img/logo_f5.png';


  constructor(
    private authService : AuthService,
    private route : Router,
    private ultilities : UltilitiesService
  ) { }

  ngOnInit(): void {
  }

  logout(){
    this.ultilities.showLoading()
    this.authService.logout(this.isAdm).then(
      () => {
        const url = this.isAdm? 'admin/login' : 'login';
        this.ultilities.hideLoading()
        this.route.navigateByUrl(url);

      }
    ).catch(
      err => {
        this.ultilities.hideLoading()
        this.ultilities.toast('Algo deu errado, tente novamente', 'error');
      }
    )
  }

  logoutClickHandler(){
    this.ultilities.swal('Deseja Sair?','','question').then(
      ()=>this.logout()
    ).catch(
      err => console.log(err)
    )
  }

}
